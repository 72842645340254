<template>
  <v-row justify="center">
    <v-dialog v-model="isAudioDialogBox" persistent max-width="450px">
      <v-card>
        <v-card-title class="text-body-1">{{ title }}</v-card-title>
        <br>
        <v-card-text>
          <v-progress-linear
            v-if="isFetchingAttchment"
            indeterminate
            rounded
            height="2"
          />
          <audio
            width="100%"
            v-else
            height="100%"
            controls
            :src="audioString"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAudioDialog">{{ $t("Close") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import editsApi from "@/api/edit";
import HereUtil from "@/util/here";

export default {
  name: "editFeatureDialogBox",
  data() {
    return {
      title: "Audio",
      audioString: undefined,
      isAudioDialogBox: true,
      isFetchingAttchment: true,
      hereUtil: new HereUtil()
    };
  },
  computed: {
    ...mapState({
      currentSelectedEditFeature: state => state.edit.currentSelectedEditFeature,
      currentFeatureInfo: state => state.featurePanel.currentFeatureInfo,
      editFillColorForAudioAndPhotoIcons: state => state.edit.editFillColorForAudioAndPhotoIcons,
    })
  },
  created() {
    let currentSelectedEditFeature= this.currentSelectedEditFeature;
    this.title = currentSelectedEditFeature.title;
    //get audio attchment and display 
    editsApi.getEditFeatureAttachment(currentSelectedEditFeature.parentId, currentSelectedEditFeature.childId).then(res => {
        this.audioString = res;
        this.isFetchingAttchment = false;
    });
  },

  methods: {
    closeAudioDialog() {
      //closing the dialogbox
      if (this.currentFeatureInfo.type === "multiEditFeatures") {
        this.$store.commit("commitAppMode", "featurePanel");
      } else {
        this.$store.commit("commitAppMode", "edit");
      }
      this.hereUtil.updateAudioColor(this.currentSelectedEditFeature.clonedElement, this.editFillColorForAudioAndPhotoIcons);
      this.currentSelectedEditFeature.clonedElement.style.zIndex = "1";
    }
  }
};
</script>
<style>
.text-align-end {
  text-align: justify;
}
</style>
